<template>
  <div class="logistics-list">
    <div class="list-container">
      <div class="search-container">
        <div class="sc-item">
          <label class="sci-title">订舱人</label>
          <el-input placeholder="请输入订舱人" v-model="bookerName" clearable>
          </el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">状态</label>
          <el-checkbox-group v-model="limitState">
            <el-checkbox v-for="s in state" :label="s.key" :key="s.key">{{
              s.value
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sc-item">
          <el-button @click="handleSearch" icon="el-icon-search" type="primary"
            >查询</el-button
          >
          <el-button @click="handleReset" icon="el-icon-refresh-left"
            >重置</el-button
          >
          <el-button icon="el-icon-plus" type="success" @click="handleAdd"
            >新增</el-button
          >
        </div>
      </div>
      <div class="table-container">
        <el-table
          :data="tableData"
          border
          header-cell-class-name="table-header"
        >
          <el-table-column fixed prop="name" label="订舱人"> </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-tag :type="scope.row.state ? 'success' : 'info'">{{
                scope.row.state ? '有效' : '失效'
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.createtime | dateFormat('YYYY-MM-DD') }}
            </template></el-table-column
          >
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text" size="small"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="pageLayout"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
    <booker-detail
      @FUNCLOSELIMIT="handleCloseLimitDetail"
      :propModel="detailModel"
      v-if="showDetail"
    ></booker-detail>
  </div>
</template>

<script>
import { PAGESIZELIST, PAGE_LAYOUT, PAGESIZE } from 'config/index'
import Customsbill from 'api/customsbill'
import { GetToken } from 'utils/auth'
import bookerDetail from 'components/booker-detail'
export default {
  components: { bookerDetail },
  name: '',
  data() {
    return {
      currentPage: 1,
      pageSize: PAGESIZE,
      pageLayout: PAGE_LAYOUT,
      pageSizes: PAGESIZELIST,
      showDetail: false, // 新增编辑运单充值
      bookerName: '', // 搜索订舱人名称
      limitState: [], // 搜索状态
      state: [
        { key: 1, value: '启用' },
        { key: 0, value: '停用' }
      ],
      detailModel: {},
      tableData: [],
      tableTotal: 0
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getList()
  },
  mounted() {},
  destroyed() {},
  methods: {
    // 关闭
    handleCloseLimitDetail(value) {
      console.log('handle funCloseLimit')
      this.detailModel = {}
      this.showDetail = false
      if (value) {
        this.currentPage = 1
        this.tableData = []
        this.getList()
      }
    },
    // 新增记录
    handleAdd() {
      this.showDetail = true
    },
    // 编辑
    handleEdit(row) {
      this.detailModel = JSON.parse(JSON.stringify(row))
      this.showDetail = true
      console.log(row)
    },
    // 查询
    handleSearch() {
      this.currentPage = 1
      this.tableData = []
      this.getList()
    },
    // 重置
    handleReset() {
      this.currentPage = 1
      this.bookerName = ''
      this.limitState = []
      this.tableData = []
      this.getList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.tableData = []
      this.getList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.tableData = []
      this.getList()
    },
    getList() {
      Customsbill.getBookerByPage({
        token:GetToken(),
        name: this.bookerName,
        state: this.limitState.join(','),
        curr: this.currentPage,
        pagesize: this.pageSize
      }).then((res) => {
        this.tableData = res.record
        this.tableTotal = +res.message
        if (res.success) {
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
