<!-- 订舱人 -->
<template>
  <div>
    <el-dialog
      title=""
      :visible="true"
      width="550px"
      :before-close="handleClose"
    >
      <div class="hoho-form-container">
        <el-form
          :model="propModel"
          label-width="120px"
          :rules="rules"
          ref="limitForm"
        >
          <el-form-item label="订舱人" prop="name">
            <el-input
              v-model="propModel.name"
              placeholder="请输入订舱人"
            ></el-input>
          </el-form-item>
          <el-form-item label="启用状态">
            <el-switch
              v-model="state"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { vxRule, rules } from '@/utils/validator'
import Customsbill from 'api/customsbill'
import { GetToken } from 'utils/auth'
export default {
  components: {},
  name: '',
  props: {
    propModel: {
      type: Object,
      default: function () {
        return {
          id: '',
          name: '',
          state: 1
        }
      }
    }
  },
  data() {
    let verifyPayFee = (rule, value, callback) => {
      if (value) {
        let { result, errMsg } = rules.Number(value)
        if (!result) {
          callback()
        } else {
          callback(new Error(errMsg))
        }
      } else {
        callback()
      }
    }
    return {
      rules: {
        name: vxRule(true, null, 'blur', '请输入订舱人')
      },
      state: 1
    }
  },
  computed: {},
  watch: {},
  created() {
    console.log('model:', this.propModel)
    if (this.propModel.id) {
      this.state = this.propModel.state
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    // 关闭
    handleClose() {
      this.$emit('FUNCLOSELIMIT')
    },
    // 提交
    handleSubmit() {
      this.$refs.limitForm.validate((valid) => {
        if (valid) {
          Customsbill.setBooker({
            ...this.propModel,
            state: this.state,
            token: GetToken()
          }).then((res) => {
            if (res.success) {
              this.$emit('FUNCLOSELIMIT', true)
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::v-deep {
  .el-input,
  .el-textarea {
    width: 300px;
  }
  .el-upload-dragger {
    width: 300px;
  }
  .preview-images {
    width: 300px !important;
    margin-left: -300px !important;
  }
  .el-image {
    width: 100px !important;
    height: 100px !important;
  }
  .el-upload__tip {
    line-height: 0;
  }
}
</style>
